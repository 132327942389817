"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.energyPrices = exports.energyPricesFactory = void 0;
const enums_1 = require("./enums");
const dates = __importStar(require("./dates"));
const modifier = (num, add = true) => (add ? num : -num);
class EnergyPrice {
    constructor(url, interval, usageType, inclBtw) {
        this.fromDate = new Date(Date.now());
        this.tillDate = new Date(Date.now());
        this.updateIntervalType = (add) => {
            switch (this.interval) {
                case enums_1.IntervalType.IntervalTypeDaily:
                    this.fromDate.setDate(this.fromDate.getDate() + modifier(1, add));
                    break;
                case enums_1.IntervalType.IntervalTypeWeekly:
                    this.fromDate.setDate(this.fromDate.getDate() + modifier(7, add));
                    break;
                case enums_1.IntervalType.IntervalTypeMonthly:
                    this.fromDate.setMonth(this.fromDate.getMonth() + modifier(1, add));
                    break;
                case enums_1.IntervalType.IntervalTypeYearly:
                    this.fromDate.setFullYear(this.fromDate.getFullYear() + modifier(1, add));
                    break;
            }
            this.setDates();
            return this;
        };
        this.fromDate.setHours(0, 0, 0, 0);
        this.tillDate.setHours(23, 59, 59, 999);
        this.url = url;
        this.interval = interval;
        this.usageType = usageType;
        this.inclBtw = inclBtw;
        this.rawData = {};
    }
    fetchPrices() {
        const url = new URL(this.url);
        const params = {
            fromDate: this.fromDate.toISOString(),
            tillDate: this.tillDate.toISOString(),
            interval: this.interval,
            usageType: this.usageType,
            inclBtw: this.inclBtw,
        };
        const entries = Object.entries(params);
        url.search = new URLSearchParams(entries).toString();
        return fetch(url.toString());
    }
    prev() {
        return this.updateIntervalType(false);
    }
    next() {
        return this.updateIntervalType(true);
    }
    setInterval(interval) {
        this.interval = interval;
        this.setDates();
        return this;
    }
    setUsageType(usageType) {
        this.usageType = usageType;
        this.setDates();
        return this;
    }
    setBtw(inclBtw) {
        this.inclBtw = inclBtw;
        return this;
    }
    setDates() {
        const from = new Date(this.fromDate);
        switch (this.interval) {
            case enums_1.IntervalType.IntervalTypeDaily:
                this.tillDate = from;
                break;
            case enums_1.IntervalType.IntervalTypeWeekly:
                this.fromDate = dates.getStartOfTheWeek(from);
                this.tillDate = dates.getEndOfTheWeek(from);
                break;
            case enums_1.IntervalType.IntervalTypeMonthly:
                this.fromDate = dates.getFirstDayOfTheMonth(from);
                this.tillDate = dates.getLastDayOfTheMonth(from);
                break;
            case enums_1.IntervalType.IntervalTypeYearly:
                this.fromDate = dates.firstDayOfYear(from);
                this.tillDate = dates.lastDayOfYear(from);
                break;
        }
        this.fromDate.setHours(0, 0, 0, 0);
        this.tillDate.setHours(23, 59, 59, 999);
    }
}
const energyPricesFactory = (interval, usageType, inclBtw) => {
    if (!exports.energyPrices) {
        const p = new EnergyPrice('https://api.energyzero.nl/v1/energyprices', interval, usageType, inclBtw);
        return p;
    }
    return exports.energyPrices;
};
exports.energyPricesFactory = energyPricesFactory;
exports.energyPrices = exports.energyPricesFactory(enums_1.IntervalType.IntervalTypeDaily, enums_1.UsageType.UsageTypeElectricity, true);
