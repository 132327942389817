"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlexLoader = void 0;
const lit_element_1 = require("lit-element");
const js_1 = require("@mdi/js");
const SVGHelpers_1 = require("../../helpers/SVGHelpers");
let FlexLoader = class FlexLoader extends lit_element_1.LitElement {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.loadingDescription = 'Data inladen…';
        this.render = () => lit_element_1.html `
            <style>
                :host {
                    display: none;
                    font-size: var(--loader-font-size);
                    color: var(--loader-color);

                    --background-color: rgba(255, 255, 255, 0.7);
                    --loader-color: #8c99ab;
                    --loader-font-size: 12px;
                }
                :host([loading]) {
                    display: grid;
                    place-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--background-color);
                    z-index: 1;
                }
                #loader-container {
                    display: grid;
                    place-items: center;
                }
                svg {
                    animation: rotate 1.4s linear infinite;
                    -webkit-animation: rotate 1.4s linear infinite;
                    -moz-animation: rotate 1.4s linear infinite;
                    width: 28px;
                    height: 20px;
                    color: var(--loader-color);
                }
                @keyframes rotate {
                    to {
                        transform: rotate(360deg);
                    }
                }
                @-webkit-keyframes rotate {
                    to {
                        -webkit-transform: rotate(360deg);
                    }
                }
                @-moz-keyframes rotate {
                    to {
                        transform: rotate(360deg);
                    }
                }
            </style>
            <div id="loader-container">
                ${SVGHelpers_1.svgMDRender(js_1.mdiAutorenew)}

                <p>${this.loadingDescription}</p>
            </div>
        `;
    }
};
__decorate([
    lit_element_1.property({ type: Boolean })
], FlexLoader.prototype, "loading", void 0);
__decorate([
    lit_element_1.property({ type: String })
], FlexLoader.prototype, "loadingDescription", void 0);
FlexLoader = __decorate([
    lit_element_1.customElement('flex-loader')
], FlexLoader);
exports.FlexLoader = FlexLoader;
