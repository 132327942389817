"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ticks = void 0;
const tickOneCent = 0.01;
const tickTwoCents = 0.02;
const tickFiveCents = 0.05;
const yAxisMaxTenCents = 0.1;
const yAxisMaxTwentyCents = 0.2;
// Sort helper that expected to return a negative value if the first argument is less than the second argument,
// zero if they're equal,
// and a positive value otherwise.
const compareNumbers = (a, b) => Number(a) - Number(b);
class Ticks {
    constructor(data, baseTickOptions = {
        max: yAxisMaxTenCents,
        min: 0,
        tick: tickOneCent,
    }) {
        this.lowest = 0;
        this.highest = 0;
        this.diff = 0;
        this.options = baseTickOptions;
        this.data = data
            .sort(compareNumbers)
            .filter((val, i, self) => self.indexOf(val) === i);
    }
    GetOptions() {
        // Setting the values based on the data
        this.setHighestAndLowest();
        // Setting the min for the token options
        this.setMin();
        // getting the diff between the min and the highest
        this.setDiff();
        // set ticks based on the diff
        this.setTick();
        // set the max
        this.setMax();
        // checking if the ticks are even
        this.makeEven();
        return this.options;
    }
    setHighestAndLowest() {
        this.lowest = 0;
        this.highest = 0;
        const numOfData = this.data.length;
        if (numOfData > 0) {
            this.lowest = this.data[0];
            this.highest = this.data[0];
            if (numOfData > 1) {
                this.highest = this.data[this.data.length - 1];
            }
        }
    }
    setMax() {
        if (this.highest > yAxisMaxTenCents) {
            this.options.max = this.highest;
        }
        const sumOfTenTimesCurrentTick = this.options.min + this.options.tick * 10;
        if (sumOfTenTimesCurrentTick > this.options.max) {
            this.options.max = sumOfTenTimesCurrentTick;
        }
    }
    setMin() {
        if (this.lowest < 0) {
            this.options.min = this.lowest;
        }
    }
    setDiff() {
        this.diff = this.highest - this.options.min;
    }
    setTick() {
        if (this.diff > yAxisMaxTenCents) {
            this.options.max += this.highest % tickTwoCents;
            this.options.tick = tickTwoCents;
        }
        if (this.diff > yAxisMaxTwentyCents) {
            this.options.max += this.highest % tickFiveCents;
            this.options.tick = tickFiveCents;
        }
    }
    makeEven() {
        if (this.options.min % tickTwoCents !== 0) {
            this.options.min -= tickOneCent;
            if (this.highest < this.options.max - tickOneCent) {
                this.options.max -= tickTwoCents;
            }
        }
    }
}
exports.Ticks = Ticks;
