"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideToolTip = exports.customToolTip = exports.getBody = void 0;
const topCorrection = 105;
/**
 * Positions the tooltip div correctly above bar chart
 */
const positionToolTipIfBiggerThanInnerWidth = (tooltipModel, tooltipEl, top, extraMargin) => {
    const offSetRightOffScreen = -2; // 35 = offset triangle from tooltip left, 95 = tooltip height + 15
    const minusExtraToStayInScreen = tooltipModel.caretX + tooltipEl.offsetWidth - window.innerWidth;
    const toolTipLeft = offSetRightOffScreen +
        tooltipModel.caretX -
        minusExtraToStayInScreen +
        extraMargin;
    tooltipEl.style.left = `${toolTipLeft}px`;
    tooltipEl.style.top = `${top + tooltipModel.caretY - topCorrection}px`;
    const triangle = document.getElementById('triangle');
    const chartTooltip = document.getElementById('chartjs-tooltip');
    if (chartTooltip != null) {
        const maxLeft = chartTooltip.offsetWidth - 51;
        const triangleLeft = tooltipModel.caretX - toolTipLeft - 35 < maxLeft
            ? tooltipModel.caretX - toolTipLeft - 35
            : maxLeft;
        if (triangle != null) {
            triangle.style.left = `${triangleLeft}px`;
        }
    }
};
/**
 * Positions the tooltip div correctly above bar chart
 */
const positionTooltipSmallerThanInnerWidth = (tooltipModel, tooltipEl, top, canvasViewportOffset) => {
    const triangle = document.getElementById('triangle');
    let triangleLeft = tooltipModel.caretX - 45; // 45 = 10 + 35 extra offset triangle from tooltip left
    if (triangleLeft < -20) {
        // -20 is half of triangle border
        triangleLeft = -20;
    }
    if (triangle != null) {
        triangle.style.left = `${triangleLeft}px`;
    }
    tooltipEl.style.left = `${canvasViewportOffset + 10}px`;
    tooltipEl.style.top = `${top + tooltipModel.caretY - topCorrection}px`; // 95 = tooltip height + 15
};
/**
 * Calculate the offset of the triangle relative to the graph
 * @return {{left: number, width: number, height: number}}
 */
const getTriangleOffset = () => {
    const triangleEl = document.getElementById('triangle');
    if (!triangleEl || !triangleEl.parentElement) {
        return { left: 0, width: 0, height: 0 };
    }
    return {
        left: triangleEl.parentElement.offsetLeft + triangleEl.offsetWidth / 2,
        width: triangleEl.offsetWidth,
        height: triangleEl.offsetHeight,
    };
};
/**
 * Positions the tooltip div correctly above bar chart
 */
const positionTooltipDynamically = (tooltipModel, tooltipEl, top, canvasViewportOffset) => {
    // LEFT POSITION
    const trianglePosition = getTriangleOffset(); // Offset triangle relative to the tooltip
    // MOBILE ONLY
    const toolTipPosition = canvasViewportOffset +
        tooltipModel.caretX -
        trianglePosition.left +
        tooltipEl.offsetWidth;
    if (window.innerWidth > toolTipPosition) {
        const mobileOffset = window.innerWidth -
            canvasViewportOffset +
            tooltipModel.caretX -
            trianglePosition.left +
            tooltipEl.offsetWidth;
        tooltipEl.style.left = `${toolTipPosition - mobileOffset}px`; //  Position for Desktop
    }
    tooltipEl.style.left = `${canvasViewportOffset + tooltipModel.caretX - trianglePosition.left}px`; //  Position for Desktop
    // TOP POSITION
    tooltipEl.style.top = `${top -
        tooltipEl.offsetHeight -
        trianglePosition.height / 2 +
        tooltipModel.caretY}px`;
};
/**
 * Positions the tooltip div correctly above bar chart
 */
const positionToolTip = (tooltipModel, tooltipEl, position, canvas, domRect) => {
    const canvasViewportOffset = canvas.getBoundingClientRect().left;
    if (tooltipModel.caretX + tooltipEl.offsetWidth > window.innerWidth) {
        positionToolTipIfBiggerThanInnerWidth(tooltipModel, tooltipEl, position.top, canvasViewportOffset);
    }
    else if (tooltipModel.caretX < 40) {
        positionTooltipSmallerThanInnerWidth(tooltipModel, tooltipEl, position.top, canvasViewportOffset);
    }
    else {
        positionTooltipDynamically(tooltipModel, tooltipEl, position.top, canvasViewportOffset);
    }
    // this position the tooltip on the component.
    const boundingRect = tooltipEl.getBoundingClientRect();
    const { style } = tooltipEl;
    const left = Number(style.left.substring(0, style.left.length - 2)) -
        domRect.left -
        boundingRect.width / 2;
    const top = Number(style.top.substring(0, style.top.length - 2)) - domRect.top;
    style.left = `${left}px`;
    style.top = `${top}px`;
};
function getBody(bodyItem) {
    return bodyItem.lines;
}
exports.getBody = getBody;
const setElementText = (tooltipModel, tooltipEl, reverse) => {
    // Set Text
    if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        const bodyLines = tooltipModel.body.map(getBody);
        const { labelColors } = tooltipModel;
        if (reverse) {
            bodyLines.reverse();
            labelColors.reverse();
            titleLines = titleLines.reverse();
        }
        let innerHtml = '';
        titleLines.forEach(title => {
            innerHtml += `<p class="tooltip-title">${title}</p>`;
        });
        const i = 1;
        const colors = labelColors[i];
        const style = `color:${colors.backgroundColor}`;
        const span = `<p style="${style}">`;
        innerHtml += `${span + bodyLines[i]}</p>`;
        if (tooltipEl) {
            tooltipEl.innerHTML = innerHtml;
        }
    }
    return tooltipEl;
};
/**
 * Fixes labels for graph
 */
const customToolTip = (tooltipModel, currentChart, canvas, reverse = true, tooltipEl, domRect) => {
    // Tooltip Element
    if (tooltipEl == null) {
        console.warn('tooltip div element is missing');
        return;
    }
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.classList.add('hide-tooltip');
        tooltipEl.style.opacity = '0';
        return;
    }
    if (tooltipEl.classList.contains('hide-tooltip')) {
        tooltipEl.classList.remove('hide-tooltip');
    }
    // Add body
    tooltipEl = setElementText(tooltipModel, tooltipEl, reverse);
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    }
    else {
        tooltipEl.classList.add('no-transform');
    }
    if (!tooltipEl) {
        console.warn('undefined tooltip element');
        return;
    }
    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    const position = currentChart.canvas.getBoundingClientRect();
    positionToolTip(tooltipModel, tooltipEl, position, canvas, domRect);
    return tooltipEl;
};
exports.customToolTip = customToolTip;
const hideToolTip = (tooltipEl) => {
    if (tooltipEl) {
        // eslint-disable-next-line no-param-reassign
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
    }
};
exports.hideToolTip = hideToolTip;
