"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTooltipTitle = exports.formatLabel = exports.format = exports.getWeekNumberfromDate = exports.lastDayOfYear = exports.firstDayOfYear = exports.firstDayPreviousMonth = exports.getEndOfWeek = exports.getLastDayOfTheMonth = exports.getFirstDayOfTheMonth = exports.getEndOfTheWeek = exports.getStartOfTheWeek = exports.DEFAULT_LOCALE = void 0;
const enums_1 = require("./enums");
exports.DEFAULT_LOCALE = 'nl-NL';
const capitalizeFirst = (s) => {
    if (typeof s !== 'string')
        return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};
const getStartOfTheWeek = (date) => {
    date = date ? new Date(date) : new Date();
    date.setHours(2, 0, 0);
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    const startDate = new Date(date.setDate(diff));
    return startDate;
};
exports.getStartOfTheWeek = getStartOfTheWeek;
const getEndOfTheWeek = (date) => {
    date = date ? new Date(date) : new Date();
    date.setHours(21, 59, 59);
    const lastday = new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 6));
    return lastday;
};
exports.getEndOfTheWeek = getEndOfTheWeek;
const getFirstDayOfTheMonth = (date) => {
    date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth(), 1);
};
exports.getFirstDayOfTheMonth = getFirstDayOfTheMonth;
const getLastDayOfTheMonth = (date) => {
    date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
exports.getLastDayOfTheMonth = getLastDayOfTheMonth;
const getEndOfWeek = (date) => {
    const lastday = date.getDate() - (date.getDay() - 1) + 6;
    return new Date(date.setDate(lastday));
};
exports.getEndOfWeek = getEndOfWeek;
const firstDayPreviousMonth = (date) => {
    date = date == null ? new Date() : new Date(date);
    date.setDate(0); // 0 will result in the last day of the previous month
    date.setDate(1); // 1 will result in the first day of the month
    return date;
};
exports.firstDayPreviousMonth = firstDayPreviousMonth;
const firstDayOfYear = (date) => new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
exports.firstDayOfYear = firstDayOfYear;
const lastDayOfYear = (date) => new Date(date.getFullYear(), 11, 31, 23, 59, 59, 99);
exports.lastDayOfYear = lastDayOfYear;
const getWeekNumberfromDate = (date) => {
    date = date == null ? new Date() : new Date(date);
    // Copy date so don't modify original
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
    // Return array of year and week number
    return weekNo;
};
exports.getWeekNumberfromDate = getWeekNumberfromDate;
const format = (date, interval) => {
    switch (interval) {
        case enums_1.IntervalType.IntervalTypeDaily:
            const day = date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                day: 'numeric',
            });
            const monthYear = date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                month: 'long',
                year: 'numeric',
            });
            return day + ' ' + capitalizeFirst(monthYear);
        case enums_1.IntervalType.IntervalTypeWeekly:
            const startOfTheWeek = exports.getStartOfTheWeek(date);
            const startDay = startOfTheWeek.toLocaleDateString(exports.DEFAULT_LOCALE, {
                day: 'numeric',
            });
            const startMonthYear = exports.getStartOfTheWeek(date).toLocaleDateString(exports.DEFAULT_LOCALE, {
                month: 'long',
                year: 'numeric',
            });
            const start = startDay + ' ' + capitalizeFirst(startMonthYear);
            const endOfTheWeek = exports.getEndOfTheWeek(date);
            const endDay = endOfTheWeek.toLocaleDateString(exports.DEFAULT_LOCALE, {
                day: 'numeric',
            });
            const endMonthYear = endOfTheWeek.toLocaleDateString(exports.DEFAULT_LOCALE, {
                month: 'long',
                year: 'numeric',
            });
            const end = endDay + ' ' + capitalizeFirst(endMonthYear);
            return `${start} - ${end}`;
        case enums_1.IntervalType.IntervalTypeMonthly:
            return capitalizeFirst(date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                month: 'long',
                year: 'numeric',
            }));
        default:
            return date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                year: 'numeric',
            });
    }
};
exports.format = format;
const formatLabel = (date, interval) => {
    switch (interval) {
        case enums_1.IntervalType.IntervalTypeDaily:
            return date
                .toLocaleDateString(exports.DEFAULT_LOCALE, {
                hour: '2-digit',
                minute: '2-digit',
            })
                .split(' ')[1];
        case enums_1.IntervalType.IntervalTypeWeekly:
            const a = date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                weekday: 'long',
            });
            return a.substring(0, 2);
        case enums_1.IntervalType.IntervalTypeMonthly:
            return date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                day: 'numeric',
            });
        case enums_1.IntervalType.IntervalTypeYearly:
            return capitalizeFirst(date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                month: 'short',
            }));
    }
};
exports.formatLabel = formatLabel;
const formatTooltipTitle = (date, interval, title) => {
    switch (interval) {
        case enums_1.IntervalType.IntervalTypeDaily:
            return `${title} - ${title.split(':')[0]}:59`;
        case enums_1.IntervalType.IntervalTypeWeekly:
        case enums_1.IntervalType.IntervalTypeMonthly:
            const weekdayDay = date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                weekday: 'short',
                day: 'numeric',
            });
            const month = capitalizeFirst(date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                month: 'short',
            }));
            return weekdayDay + ' ' + month;
        case enums_1.IntervalType.IntervalTypeYearly:
            return capitalizeFirst(date.toLocaleDateString(exports.DEFAULT_LOCALE, {
                month: 'long',
            }));
    }
};
exports.formatTooltipTitle = formatTooltipTitle;
