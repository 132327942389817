"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawData = void 0;
const enums_1 = require("./enums");
const dates_1 = require("./dates");
const Fetch_1 = require("./Fetch");
class RawData {
    constructor(data, interval, from, till) {
        this.getLabels = () => {
            const labels = [];
            const cur = new Date(this.from);
            switch (this.interval) {
                case enums_1.IntervalType.IntervalTypeDaily:
                    while (cur < this.till) {
                        labels.push(dates_1.formatLabel(cur, this.interval));
                        cur.setTime(cur.getTime() + 60 * 60 * 1000);
                    }
                    break;
                case enums_1.IntervalType.IntervalTypeWeekly:
                case enums_1.IntervalType.IntervalTypeMonthly:
                    while (cur < this.till) {
                        labels.push(dates_1.formatLabel(cur, this.interval));
                        cur.setDate(cur.getDate() + 1);
                    }
                    break;
                case enums_1.IntervalType.IntervalTypeYearly:
                    while (cur < this.till) {
                        labels.push(dates_1.formatLabel(cur, this.interval));
                        cur.setMonth(cur.getMonth() + 1);
                    }
                    break;
                default:
                    break;
            }
            return labels;
        };
        this.getDataSet = (labels) => {
            if (labels.length === 0) {
                return [];
            }
            const totalPrices = this.rawdata.Prices.length;
            const data = [];
            let priceIndex = 0;
            if (!Fetch_1.energyPrices) {
                return [];
            }
            labels.forEach((item, index) => {
                const priceItem = this.rawdata.Prices[priceIndex];
                if (index >= totalPrices) {
                    data.push(0);
                    return;
                }
                if (dates_1.formatLabel(new Date(priceItem.readingDate), this.interval) ===
                    item) {
                    data.push(priceItem.price);
                    priceIndex++;
                    return;
                }
                data.push(0);
            });
            return data;
        };
        this.rawdata = data;
        this.interval = interval;
        this.from = from;
        this.till = till;
    }
}
exports.RawData = RawData;
