"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsageType = exports.IntervalType = void 0;
var IntervalType;
(function (IntervalType) {
    IntervalType[IntervalType["IntervalTypeDaily"] = 4] = "IntervalTypeDaily";
    IntervalType[IntervalType["IntervalTypeMonthly"] = 5] = "IntervalTypeMonthly";
    IntervalType[IntervalType["IntervalTypeYearly"] = 6] = "IntervalTypeYearly";
    IntervalType[IntervalType["IntervalTypeWeekly"] = 9] = "IntervalTypeWeekly";
})(IntervalType = exports.IntervalType || (exports.IntervalType = {}));
var UsageType;
(function (UsageType) {
    UsageType[UsageType["UsageTypeElectricity"] = 1] = "UsageTypeElectricity";
    UsageType[UsageType["UsageTypeGas"] = 3] = "UsageTypeGas";
})(UsageType = exports.UsageType || (exports.UsageType = {}));
