"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_element_1 = require("lit-element");
exports.default = lit_element_1.css `
    :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: var(--font-family);
        font-size: var(--font-size);
        box-sizing: border-box;

        /* color styles */
        --text-color: #b1bed0;
        --active-color: #1e2836;
        --transparant-background-color: #3a7ddb1a;
        --svg-color: #3a7ddb;
        --icon-width: 20px;
        --font-size: inherit;
        --border-radius: 5px;
        --transition-s: 0.3s;
    }
    svg {
        width: var(--icon-width);
        color: var(--svg-color);
    }

    lion-button:hover,
    lion-button.active {
        color: var(--active-color);
    }

    lion-button {
        display: unset;
        background: none !important;
        cursor: pointer;
        color: var(--text-color);
    }

    @media only screen and (max-width: 550px) {
        svg {
            display: none;
        }
        lion-button {
            width: 100%;
        }
        lion-button:not(:active) {
            background: var(--transparant-background-color) !important;
        }
        lion-button:active,
        lion-button:active:hover,
        lion-button.active,
        lion-button.active:hover {
            background: var(--svg-color) !important;
            color: white;
        }
        lion-button:hover {
            background-color: var(--transparant-background-color) !important;
            color: var(--svg-color);
            transition: var(--transition-s) ease-in-out all;
        }

        lion-button:first-of-type {
            border-radius: var(--border-radius) 0 0 var(--border-radius);
        }
        lion-button:last-of-type {
            border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
    }
`;
