"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_element_1 = require("lit-element");
exports.default = lit_element_1.css `
    :host {
        display: flex;
        color: var(--text-color);
        position: relative;
        flex-direction: column;

        /* color styles */
        --text-color: #1e2836;
        --white: #fff;
        --primary-color: #b1bed0;
        --background-color: var(--white);
        --bar-color: #3a7ddb;
        --bar-average-color: rgba(58, 125, 219, 0.5);
        --button-color: var(--white);
        --button-bg-color: var(--primary-color);
        --information-button--color: var(--primary-color);
        --information-button--color-hover: var(--bar-color);
        --footer--background-color: #f4f6f9;

        /* loader styles */
        --flex-loader-color: #8c99ab;
        --flex-loader-font-size: 12px;
        --flex-loader-background-color: rgba(255, 255, 255, 0.7);

        /* custom styles */
        --font-size: 12px;
        --font-family: inherit;
        --icon--height-width: 18px;
        --title-font-weight: 600;
        /* arrow icon styles */
        --arrow--background-color: var(--primary-color);
        --arrow__container--height-width: 32px;
        --arrow--border-radius: 50%;
        /* disclaimer styles */
        --disclaimer-color: var(--primary-color);
        --disclaimer--font-size: 12px;

        /* legenda */
        --legenda-h1-size: 36px;
        --legenda-font-size: 16px;
        --legenda-line-height: 24px;
        --legenda-letter-spacing: -0.25px;
        --legenda-max-width: 760px;

        /* error message */
        --error-color: var(--primary-color);
        --error-font-size: var(--font-size);

        /* 
            specific custom styling
        */
        --header__icon--max-height: var(--icon--height-width);
        --header--padding: 20px 20px 0 20px;
        --header__mobile--padding: var(--header-padding);
        --header--padding-bottom: 28px;
        /* arrow */
        --arrow__container--height: var(--arrow__container--height-width);
        --arrow__container--width: var(--arrow__container--height-width);
        --arrow--height: var(--icon--height-width);
        --arrow--width: var(--icon--height-width);
        /* footer buttons */
        --footer-text-color: var(--primary-color);
        --footer-text-active-color: var(--text-color);
        --footer--font-size: var(--legenda-font-size);
        --footer--padding: 22px 50px 18px;
        --footer__mobile--padding: 22px 4px 18px;
        /* legenda */
        --legenda-h1-color: var(--text-color);
        --legenda-font-color: var(--flex-loader-color);
        --legenda-description-color: var(--bar-color);
        --legenda-description-font-size: 14px;
    }

    lion-button {
        background-color: var(--button-bg-color);
        color: var(--button-color);
    }

    body,
    p,
    a,
    h1,
    h2,
    h3,
    span {
        font-family: var(--font-family);
        font-size: var(--font-size);
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    /* header */
    header {
        padding: var(--header--padding);
    }
    header #header-information {
        display: flex;
        justify-content: flex-end;
    }
    header #header-information svg {
        max-height: var(--header__icon--max-height);
        color: var(--information-button--color);
        cursor: pointer;
    }
    header #header-information svg:hover {
        color: var(--information-button--color-hover);
    }
    header,
    main {
        background-color: var(--background-color);
    }

    #main-legenda,
    :host([legenda]) #main-content,
    main header.hidden {
        visibility: hidden;
        height: 0;
    }
    :host([legenda]) #main-legenda,
    #main-content {
        visibility: visible;
        height: auto;
    }

    #main-content .date {
        font-weight: var(--title-font-weight);
    }

    /* main component */
    main {
        padding: 0 20px 70px;
    }
    /* chart navigation */
    main header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: var(--header--padding-bottom);
    }

    main header p {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 230px;
        text-align: center;
        min-height: 50px;
        user-select: none;
        font-size: var(--chart-title--font-size);
    }

    main header .arrow-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--arrow__container--width);
        height: var(--arrow__container--height);
        background-color: var(--arrow--background-color);
        border-radius: var(--arrow--border-radius);
    }

    main header .arrow-container svg {
        color: var(--white);
        background-color: var(--primary-color);
        height: var(--arrow--height);
        width: var(--arrow--width);
        cursor: pointer;
    }

    main header .arrow-container.left svg {
        transform: rotate(180deg);
    }

    /* chart */
    #chart {
        width: 100% !important;
    }
    flex-loader {
        --loader-color: var(--flex-loader-color);
        --loader-font-size: var(--flex-loader-font-size);
        --background-color: var(--flex-loader-background-color);
    }
    main #chart-container {
        position: relative;
    }
    main canvas {
        position: relative;
        margin: auto;
        max-height: 390px !important;
        width: 100%;
    }
    /* Footer */
    footer {
        min-height: 40px;
        max-height: 80px;
        padding: var(--footer--padding);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--footer--background-color);
    }
    footer[hidden] > * {
        display: none;
    }
    footer flex-selector-component {
        --font-size: 1rem;
        --text-color: var(--footer-text-color);
        --active-color: var(--footer-text-active-color);
        --svg-color: var(--bar-color);
    }

    /* disclaimer */
    .disclaimer {
        margin-top: 24px;
    }
    .disclaimer,
    .disclaimer > a,
    .disclaimer > a:visited {
        color: var(--disclaimer-color);
        font-family: var(--font-family);
        font-size: var(--disclaimer--font-size);
        text-align: center;
        user-select: none;
    }

    /* legenda */
    #main-legenda-header {
        padding-top: 0;
        font-size: var(--legenda-description-font-size);
        color: var(--legenda-font-color);
    }
    #main-legenda-header {
        justify-content: flex-start;
        font-size: var(--legenda-font-size);
    }
    #main-legenda-header p {
        min-width: auto;
    }
    main header[hidden] {
        display: none;
    }
    main .error-message {
        color: var(--error-color);
        font-size: var(--error-font-size);
        text-align: center;
    }

    #main-legenda-header > *:not(:last-child) {
        padding-right: 40px;
    }

    #main-legenda-header h3 {
        font-size: var(--legenda-font-size);
        color: var(--legenda-description-color);
    }

    #main-legenda-header span:last-of-type {
        margin-right: 10px;
    }
    #main-legenda-header span.dashes:first-of-type {
        margin-right: 2px;
    }

    #main-legenda-header span.circle {
        width: 12px;
        height: 12px;
        background-color: var(--bar-color);
        border-radius: 50%;
    }

    #main-legenda-header span.dashes {
        width: 6px;
        height: 1px;
        border-bottom: 3px dashed var(--bar-average-color);
    }

    #legenda h1 {
        font-size: var(--legenda-h1-size);
        color: var(--legenda-h1-color);
        max-width: var(--legenda-max-width);
    }

    #legenda p {
        font-size: var(--legenda-font-size);
        color: var(--legenda-font-color);
        line-height: var(--legenda-line-height);
        letter-spacing: var(--legenda-letter-spacing);
        max-width: var(--legenda-max-width);
    }

    /* Tooltip */
    #chartjs-tooltip {
        opacity: 0;
        position: absolute;
        border: 1px solid var(--primary-color);
        background-color: var(--white);
        padding: 18px 24px 16px;
        border-radius: 2px;
    }
    #chartjs-tooltip p {
        white-space: nowrap;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        padding: 0;
    }

    /* loader */

    @media only screen and (max-width: 700px) {
        footer {
            padding: var(--footer__mobile--padding);
        }

        header {
            padding: var(--header__mobile--padding);
        }
    }
    @media only screen and (max-width: 550px) {
        main {
            padding: 0 10px 30px;
        }
        footer {
            flex-direction: column;
            max-height: fit-content;
            --footer--padding: 10px 4px;
        }
        flex-selector-component {
            --font-size: 1rem;
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 0 8px;
            margin-bottom: 4px;
        }
        #main-legenda-header {
            padding-left: unset;
            padding-right: unset;
            justify-content: center;
        }
        #main-legenda-header > *:not(:last-child) {
            padding-right: 20px;
        }
        header #header-information {
            margin-right: 8px;
        }
    }
`;
